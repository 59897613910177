//Dependencies
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import axios from "axios";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useHistory } from "react-router-dom";

//Components
import PageLoader from "../components/PageLoader";

//Assets
import "../stylesheets/MenuForm.css";
import "react-tabs/style/react-tabs.css";

//Utils
import * as actions from "../actions";
import { getAll, update } from "../utils/services";
import { entities } from "../utils/partials.json";
import { translations } from "../utils/translations.json";
import {
  modalTranslationSuccessful,
  modalTranslationError,
} from "../utils/modals";

const TranslationsContainer = (props) => {
  const {
    selectedEntity,
    entity,
    loggedUser: { restaurant_id, codes, role },
    showLoadingOverlay,
  } = props;
  const { t } = useTranslation();
  const [languages, setLanguages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [attributes, setAttributes] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [newTranslations, setNewTranslations] = useState([]);
  const [newTranslationsItems, setNewTranslationsItems] = useState({});

  const readOnly =
    ["branch_admin", "branch_user"].includes(role) && !selectedEntity.branch_id;

  const { register, handleSubmit } = useForm();
  const history = useHistory();
  const groupBy = function (xs, key) {
    return xs?.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  const changeNewTranslation = (id, att, value) => {
    const translation = selectedEntity.translations.find((t) => t.id === id);
    let aux = [...newTranslations];
    if (translation.description !== value) {
      const inArray = newTranslations.find((t) => t.id === id);
      if (inArray) {
        aux = newTranslations.map((t) => {
          if (t.id === id) {
            return { ...t, description: value };
          }
          return t;
        });
      } else {
        aux.push({ id, description: value, att, locale: translation.locale });
      }
      setNewTranslations([...aux]);
    } else {
      const index = newTranslations.findIndex((t) => t.id === id);
      aux.splice(index, 1);
      setNewTranslations([...aux]);
    }
  };

  const changeNewTranslationItem = (item, id, att, value) => {
    const translation = item.translations.find((t) => t.id === id);
    let aux = [...newTranslationsItems[item.id]];
    if (translation.description !== value) {
      const inArray = newTranslationsItems[item.id].find((t) => t.id === id);
      if (inArray) {
        aux = newTranslationsItems[item.id].map((t) => {
          if (t.id === id) {
            return { ...t, description: value };
          }
          return t;
        });
      } else {
        aux.push({ id, description: value, att, locale: translation.locale });
      }
      setNewTranslationsItems({ ...newTranslationsItems, [item.id]: aux });
    } else {
      const index = newTranslationsItems[item.id].findIndex((t) => t.id === id);
      aux.splice(index, 1);
      setNewTranslationsItems({ ...newTranslationsItems, [item.id]: aux });
    }
  };

  useEffect(() => {
    if (selectedEntity?.items) {
      const itemsDictionary = {};
      selectedEntity.items.forEach((item) => (itemsDictionary[item.id] = []));
      setNewTranslationsItems(itemsDictionary);
    }
  }, []);

  useEffect(() => {
    axios
      .get(getAll.languages)
      .then(({ data }) => {
        const ORGANIZED_LANGUAGES = data.languages.sort((a) =>
          a.code == selectedEntity.translations[0].locale ? -1 : 1
        );
        let restaurant = window.localStorage.getItem("restaurant");
        const availableCodes = restaurant
          ? JSON.parse(restaurant).codes
          : codes;
        if (availableCodes) {
          setLanguages(
            ORGANIZED_LANGUAGES.filter((l) => availableCodes.includes(l.code))
          );
        } else {
          setLanguages(ORGANIZED_LANGUAGES);
        }
      })
      .then(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    setAttributes(groupBy(selectedEntity.translations, "att"));
  }, [selectedEntity]);

  const handleGetTranslation = (language_code) => {
    return selectedEntity.translations.filter(
      ({ locale }) => locale === language_code
    )[0];
  };

  const handleGetAtt = (entity) => {
    return translations[entity];
  };

  const onSubmit = (data, e) => {
    showLoadingOverlay(true);
    const ATT_NUMBER = 4;
    const iterations = Object.keys(data).length / ATT_NUMBER;
    const enty_update = {
      ...selectedEntity,
      translations_attributes: newTranslations,
    };
    delete enty_update.translations;

    const descriptions = Object.keys(data).filter((k) =>
      k.match("description")
    );
    const values = descriptions.map((d) => data[d]).join(", ");

    if (selectedEntity.items?.length > 0) {
      enty_update["items_attributes"] = Object.keys(newTranslationsItems).map(
        (itemId) => ({
          id: itemId,
          translations_attributes: newTranslationsItems[itemId],
        })
      );
    }
    axios
      .put(update[entity](restaurant_id || selectedEntity.restaurant_id, selectedEntity.id), enty_update)
      .then(() =>
        modalTranslationSuccessful("actualizado", values, t, () =>
          history.goBack()
        )
      )
      .catch(() => modalTranslationError("actualizar", data.description, t))
      .finally(() => showLoadingOverlay(false));
  };

  if (isLoading) return <PageLoader />;

  const attributeTitle = (attribute) => {
    if (entities[entity].attributes) {
      return (
        <label className="cartear-form-label">
          {entities[entity].attributes[attribute]}
        </label>
      );
    }
    return <></>;
  };

  return (
    <section>
      <form
        className="cartear-menu-form"
        onSubmit={(e) => handleSubmit(onSubmit)(e).catch((e) => console.log(e))}
      >
        <Tabs
          className="w-100"
          selectedIndex={currentIndex}
          onSelect={(index) => setCurrentIndex(index)}
        >
          <TabList>
            {languages.map((l, index) => (
              <Tab key={index}>{l.value}</Tab>
            ))}
          </TabList>
          {languages.map((l) => (
            <TabPanel key={l.code}>
              <section className="tab-content-container mt-16">
                <section className="row">
                  <section className="form-group">
                    <label className="cartear-form-label">
                      {entities[entity].singular}
                    </label>
                    <br />
                    {Object.keys(attributes).map((attribute, index) => {
                      const translation = attributes[attribute].find(
                        (a) => a.locale === l.code
                      );
                      const newTranslation = newTranslations.find(
                        (a) => a.id === translation.id
                      );
                      return (
                        <div key={`${translation?.id}`}>
                          {attributeTitle(attribute)}
                          <input
                            readOnly={readOnly}
                            name={`description${index}`}
                            className="form-control cartear-form-input"
                            value={
                              newTranslation?.description !== undefined
                                ? newTranslation?.description
                                : translation?.description
                            }
                            onChange={(e) =>
                              changeNewTranslation(
                                translation?.id,
                                attribute,
                                e.target.value
                              )
                            }
                            ref={register({
                              required: false,
                            })}
                          />
                          <input
                            name={`id${index}`}
                            type="hidden"
                            defaultValue={translation?.id}
                            ref={register}
                          />
                          <input
                            name={`locale${index}`}
                            type="hidden"
                            defaultValue={translation?.code}
                            ref={register}
                          />
                          <input
                            name={`att${index}`}
                            type="hidden"
                            defaultValue={attribute}
                            ref={register}
                          />
                        </div>
                      );
                    })}
                    {selectedEntity.items &&
                      selectedEntity.items.map((item, index) => {
                        console.log(item);
                        const translation = item.translations.find(
                          (translation) => translation.locale === l.code
                        );
                        const newTranslation = newTranslationsItems[
                          item.id
                        ].find((a) => a.id === translation.id);
                        console.log(translation);
                        return (
                          <div key={`${translation?.id}`}>
                            {`Item ${index + 1}`}
                            <input
                              readOnly={readOnly}
                              name={`itemDescription${index}`}
                              className="form-control cartear-form-input"
                              value={
                                newTranslation?.description !== undefined
                                  ? newTranslation?.description
                                  : translation?.description
                              }
                              onChange={(e) =>
                                changeNewTranslationItem(
                                  item,
                                  translation?.id,
                                  "name",
                                  e.target.value
                                )
                              }
                              ref={register({
                                required: false,
                              })}
                            />
                            <input
                              name={`itemId${index}`}
                              type="hidden"
                              defaultValue={translation?.id}
                              ref={register}
                            />
                            <input
                              name={`itemLocale${index}`}
                              type="hidden"
                              defaultValue={translation?.code}
                              ref={register}
                            />
                            <input
                              name={`itemAtt${index}`}
                              type="hidden"
                              defaultValue={item.name}
                              ref={register}
                            />
                          </div>
                        );
                      })}
                  </section>
                </section>
              </section>
            </TabPanel>
          ))}
        </Tabs>
        {!readOnly && (
          <section className="row">
            <section className="form-submit-btn">
              <input
                type="submit"
                className="btn gradient-button"
                value="GUARDAR"
              />
            </section>
          </section>
        )}
      </form>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedUser: state.loggedUser,
  };
};

export default connect(mapStateToProps, actions)(TranslationsContainer);
