//Dependencies
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

//Components
import MenuForm from "../components/MenuForm";
import PageLoader from "../components/PageLoader";

//Assets
import { setSelectedEntity, setSchedule } from "../actions";

//Utils
import { get } from "../utils/services";

const MenuEdit = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const { setSelectedEntity, selectedEntity, loggedUser, setSchedule } = props;
  const { id } = useParams();
  const RESTAURANT_ID = loggedUser.restaurant_id;
  const BRANCH_ID = loggedUser.role == "branch_user" ? loggedUser.branch_id : null;

  useEffect(() => {
    axios
      .get(get.menus(id, RESTAURANT_ID, BRANCH_ID))
      .then((res) => {
        setSelectedEntity(res.data);
        setSchedule(res.data.schedules);
      })
      .then(() => setIsLoading(false));
  }, []);

  return isLoading ? (
    <PageLoader />
  ) : (
    <section className="container">
      <div className="form-title">
        <h3>Editar Menu</h3>
      </div>
      <MenuForm action={"edit"} menu={selectedEntity} schedules={selectedEntity.schedules} setIsLoading={setIsLoading}/>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedEntity: state.selectedEntity,
    loggedUser: state.loggedUser,
  };
};

const mapDispatchToProps = {
  setSelectedEntity,
  setSchedule,
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuEdit);
