// Actions for reducers
export const setOnlyAllergens = (payload) => ({
  type: "SET_ONLY_ALLERGENS",
  payload,
});

export const setOnlyCategories = (payload) => ({
  type: "SET_ONLY_CATEGORIES",
  payload,
});

export const setOnlyProducts = (payload) => ({
  type: "SET_ONLY_PRODUCTS",
  payload,
});
export const setOnlyVariants = (payload) => ({
  type: "SET_ONLY_VARIANTS",
  payload,
});

export const setOnlyToppings = (payload) => ({
  type: "SET_ONLY_TOPPINGS",
  payload,
});

export const setOnlyAccompaniments = (payload) => ({
  type: "SET_ONLY_ACCOMPANIMENTS",
  payload,
});

export const setActiveMenu = (payload) => ({
  type: "SET_ACTIVE_MENU",
  payload,
});

export const getUserLogged = (payload) => ({
  type: "GET_USER_LOGGED",
  payload,
});

export const setIsLoading = (payload) => ({
  type: "SET_IS_LOADING",
  payload,
});

export const setCurrentSection = (payload) => ({
  type: "SET_CURRENT_SECTION",
  payload,
});

export const setRestaurants = (payload) => ({
  type: "SET_RESTAURANTS",
  payload,
});

export const updateRestaurant = (payload) => ({
  type: "UPDATE_RESTAURANT",
  payload,
});

export const removeRestaurant = (payload) => ({
  type: "REMOVE_RESTAURANT",
  payload,
});

export const clearRestaurants = (payload) => ({
  type: "CLEAR_RESTAURANTS",
  payload,
});

export const setBranches = (payload) => ({
  type: "SET_BRANCHES",
  payload,
});

export const updateBranch = (payload) => ({
  type: "UPDATE_BRANCH",
  payload,
});

export const removeBranch = (payload) => ({
  type: "REMOVE_BRANCH",
  payload,
});

export const clearBranches = (payload) => ({
  type: "CLEAR_BRANCHES",
  payload,
});

export const setProducts = (payload) => ({
  type: "SET_PRODUCTS",
  payload,
});

export const updateProduct = (payload) => ({
  type: "UPDATE_PRODUCT",
  payload,
});

export const removeProduct = (payload) => ({
  type: "REMOVE_PRODUCT",
  payload,
});

export const clearProducts = (payload) => ({
  type: "CLEAR_PRODUCTS",
  payload,
});

export const setAllergens = (payload) => ({
  type: "SET_ALLERGENS",
  payload,
});

export const updateAllergen = (payload) => ({
  type: "UPDATE_ALLERGEN",
  payload,
});

export const removeAllergen = (payload) => ({
  type: "REMOVE_ALLERGEN",
  payload,
});

export const clearAllergens = (payload) => ({
  type: "CLEAR_ALLERGENS",
  payload,
});

export const setTags = (payload) => ({
  type: "SET_TAGS",
  payload,
});

export const updateTag = (payload) => ({
  type: "UPDATE_TAG",
  payload,
});

export const removeTag = (payload) => ({
  type: "REMOVE_TAG",
  payload,
});

export const clearTags = (payload) => ({
  type: "CLEAR_TAGS",
  payload,
});

export const setPaymentMethods = (payload) => ({
  type: "SET_PAYMENT_METHODS",
  payload,
});

export const clearPaymentMethods = (payload) => ({
  type: "CLEAR_PAYMENT_METHODS",
  payload,
});

export const setShippingMethods = (payload) => ({
  type: "SET_SHIPPING_METHODS",
  payload,
});

export const clearShippingMethods = (payload) => ({
  type: "CLEAR_SHIPPING_METHODS",
  payload,
});

export const setPlans = (payload) => ({
  type: "SET_PLANS",
  payload,
});

export const updatePlan = (payload) => ({
  type: "UPDATE_PLAN",
  payload,
});

export const removePlan = (payload) => ({
  type: "REMOVE_PLAN",
  payload,
});

export const clearPlans = (payload) => ({
  type: "CLEAR_PLANS",
  payload,
});

export const setPlanPromotions = (payload) => ({
  type: "SET_PLAN_PROMOTIONS",
  payload,
});

export const updatePlanPromotion = (payload) => ({
  type: "UPDATE_PLAN_PROMOTION",
  payload,
});

export const removePlanPromotion = (payload) => ({
  type: "REMOVE_PLAN_PROMOTION",
  payload,
});

export const clearPlanPromotions = (payload) => ({
  type: "CLEAR_PLAN_PROMOTIONS",
  payload,
});

export const setUsers = (payload) => ({
  type: "SET_USERS",
  payload,
});

export const updateUser = (payload) => ({
  type: "UPDATE_USER",
  payload,
});

export const removeUser = (payload) => ({
  type: "REMOVE_USER",
  payload,
});

export const clearUsers = (payload) => ({
  type: "CLEAR_USERS",
  payload,
});

export const setCurrencies = (payload) => ({
  type: "SET_CURRENCIES",
  payload,
});

export const updateCurrency = (payload) => ({
  type: "UPDATE_CURRENCY",
  payload,
});

export const removeCurrency = (payload) => ({
  type: "REMOVE_CURRENCY",
  payload,
});

export const clearCurrencies = (payload) => ({
  type: "CLEAR_CURRENCIES",
  payload,
});

export const setCountries = (payload) => ({
  type: "SET_COUNTRIES",
  payload,
});

export const updateCountry = (payload) => ({
  type: "UPDATE_COUNTRY",
  payload,
});

export const removeCountry = (payload) => ({
  type: "REMOVE_COUNTRY",
  payload,
});

export const clearCountries = (payload) => ({
  type: "CLEAR_COUNTRIES",
  payload,
});

export const setMenus = (payload) => ({
  type: "SET_MENUS",
  payload,
});

export const updateMenu = (payload) => ({
  type: "UPDATE_MENU",
  payload,
});

export const removeMenu = (payload) => ({
  type: "REMOVE_MENU",
  payload,
});

export const clearMenus = (payload) => ({
  type: "CLEAR_MENUS",
  payload,
});

export const setCategories = (payload) => ({
  type: "SET_CATEGORIES",
  payload,
});

export const updateCategory = (payload) => ({
  type: "UPDATE_CATEGORY",
  payload,
});

export const removeCategory = (payload) => ({
  type: "REMOVE_CATEGORY",
  payload,
});

export const clearCategories = (payload) => ({
  type: "CLEAR_CATEGORIES",
  payload,
});

export const setToppings = (payload) => ({
  type: "SET_TOPPINGS",
  payload,
});

export const updateTopping = (payload) => ({
  type: "UPDATE_TOPPING",
  payload,
});

export const removeTopping = (payload) => ({
  type: "REMOVE_TOPPING",
  payload,
});

export const clearToppings = (payload) => ({
  type: "CLEAR_TOPPINGS",
  payload,
});

export const setAccompaniments = (payload) => ({
  type: "SET_ACCOMPANIMENTS",
  payload,
});

export const updateAccompaniment = (payload) => ({
  type: "UPDATE_ACCOMPANIMENT",
  payload,
});

export const removeAccompaniment = (payload) => ({
  type: "REMOVE_ACCOMPANIMENT",
  payload,
});

export const clearAccompaniments = (payload) => ({
  type: "CLEAR_ACCOMPANIMENTS",
  payload,
});

export const setVariants = (payload) => ({
  type: "SET_VARIANTS",
  payload,
});

export const updateVariant = (payload) => ({
  type: "UPDATE_VARIANT",
  payload,
});

export const removeVariant = (payload) => ({
  type: "REMOVE_VARIANT",
  payload,
});

export const clearVariants = (payload) => ({
  type: "CLEAR_VARIANTS",
  payload,
});

export const setMobileVersions = (payload) => ({
  type: "SET_MOBILE_VERSIONS",
  payload,
});

export const updateMobileVersion = (payload) => ({
  type: "UPDATE_MOBILE_VERSION",
  payload,
});

export const removeMobileVersion = (payload) => ({
  type: "REMOVE_MOBILE_VERSION",
  payload,
});

export const clearMobileVersions = (payload) => ({
  type: "CLEAR_MOBILE_VERSIONS",
  payload,
});

export const setSchedule = (payload) => ({
  type: "SET_SCHEDULE",
  payload,
});

export const updateSchedule = (payload) => ({
  type: "UPDATE_SCHEDULE",
  payload,
});

export const removeSchedule = (payload) => ({
  type: "REMOVE_SCHEDULE",
  payload,
});

export const clearSchedules = (payload) => ({
  type: "CLEAR_SCHEDULES",
  payload,
});

export const addMenuCategory = (payload) => ({
  type: "ADD_MENU_CATEGORY",
  payload,
});

export const updateMenuCategory = (payload) => ({
  type: "UPDATE_MENU_CATEGORY",
  payload,
});

export const removeMenuCategory = (payload) => ({
  type: "REMOVE_MENU_CATEGORY",
  payload,
});

export const clearMenuCategories = (payload) => ({
  type: "CLEAR_MENU_CATEGORIES",
  payload,
});

export const addEntityItem = (payload) => ({
  type: "ADD_ENTITY_ITEM",
  payload,
});

export const updateEntityItem = (payload) => ({
  type: "UPDATE_ENTITY_ITEM",
  payload,
});

export const removeEntityItem = (payload) => ({
  type: "REMOVE_ENTITY_ITEM",
  payload,
});

export const clearEntityItems = (payload) => ({
  type: "CLEAR_ENTITY_ITEMS",
  payload,
});

export const addPlanCountry = (payload) => ({
  type: "ADD_PLAN_COUNTRY",
  payload,
});

export const updatePlanCountry = (payload) => ({
  type: "UPDATE_PLAN_COUNTRY",
  payload,
});

export const removePlanCountry = (payload) => ({
  type: "REMOVE_PLAN_COUNTRY",
  payload,
});

export const clearPlanCountries = (payload) => ({
  type: "CLEAR_PLAN_COUNTRIES",
  payload,
});

export const addMenuCategoryProduct = (payload) => ({
  type: "ADD_MENU_CATEGORY_PRODUCT",
  payload,
});

export const removeMenuCategoryProduct = (payload) => ({
  type: "REMOVE_MENU_CATEGORY_PRODUCT",
  payload,
});

export const reorderMenuCategoryProduct = (payload) => ({
  type: "REORDER_MENU_CATEGORY_PRODUCT",
  payload,
});

export const updateMenuCategoryProduct = (payload) => ({
  type: "UPDATE_MENU_CATEGORY_PRODUCT",
  payload,
});

export const clearMenuCategoryProduct = (payload) => ({
  type: "CLEAR_MENU_CATEGORY_PRODUCT",
  payload,
});

export const setSuggestions = (payload) => ({
  type: "SET_SUGGESTIONS",
  payload,
});

export const clearSuggestions = (payload) => ({
  type: "CLEAR_SUGGESTIONS",
  payload,
});

export const setNotifications = (payload) => ({
  type: "SET_NOTIFICATIONS",
  payload,
});

export const clearNotifications = (payload) => ({
  type: "CLEAR_NOTIFICATIONS",
  payload,
});

export const setModels = (payload) => ({
  type: "SET_MODELS",
  payload,
});

export const clearModels = (payload) => ({
  type: "CLEAR_MODELS",
  payload,
});

export const setPaymentTransactions = (payload) => ({
  type: "SET_PAYMENT_TRANSACTIONS",
  payload,
});

export const updatePaymentTransaction = (payload) => ({
  type: "UPDATE_PAYMENT_TRANSACTION",
  payload,
});

export const removePaymentTransaction = (payload) => ({
  type: "REMOVE_PAYMENT_TRANSACTION",
  payload,
});

export const clearPaymentTransactions = (payload) => ({
  type: "CLEAR_PAYMENT_TRANSACTIONS",
  payload,
});

export const setTables = (payload) => ({
  type: "SET_TABLES",
  payload,
});

export const updateTable = (payload) => ({
  type: "UPDATE_TABLE",
  payload,
});

export const removeTable = (payload) => ({
  type: "REMOVE_TABLE",
  payload,
});

export const clearTables = (payload) => ({
  type: "CLEAR_TABLES",
  payload,
});

export const setNextPage = (payload) => ({
  type: "SET_NEXT_PAGE",
  payload,
});

export const setHasMore = (payload) => ({
  type: "SET_HAS_MORE",
  payload,
});

export const setFilteredItems = (payload) => ({
  type: "SET_FILTERED_ITEMS",
  payload,
});

export const setSelectedEntity = (payload) => ({
  type: "SET_SELECTED_ENTITY",
  payload,
});

export const setEntity = (payload) => ({
  type: "SET_ENTITY",
  payload,
});

export const setIsVisible = (payload) => ({
  type: "SET_IS_VISIBLE",
  payload,
});

export const setPaymentId = (payload) => ({
  type: "SET_PAYMENT_ID",
  payload,
});

export const setLoggedUser = (payload) => ({
  type: "SET_LOGGED_USER",
  payload,
});

export const showLoadingOverlay = (payload) => ({
  type: "SHOW_LOADING_OVERLAY",
  payload,
});
