// Default Select Values

export const payment_methods = [
  {
    label: "Pagos360",
    value: 0,
  },
  {
    label: "Paypal",
    value: 1,
  },
];

export const payment_options = [
  {
    label: "Manual",
    value: 0,
  },
  {
    label: "Débito automático",
    value: 1,
  },
];

export const auto_debit_options = [
  {
    label: "Tarjeta",
    value: 0,
  },
  {
    label: "CBU",
    value: 1,
  },
];

export const suscription_periods = [
  {
    label: "1 Mes",
    value: 1,
  },
  {
    label: "2 Meses",
    value: 2,
  },
  {
    label: "3 Meses",
    value: 3,
  },
  {
    label: "4 Meses",
    value: 4,
  },
  {
    label: "5 Meses",
    value: 5,
  },
  {
    label: "6 Meses",
    value: 6,
  },
  {
    label: "7 Meses",
    value: 7,
  },
  {
    label: "8 Meses",
    value: 8,
  },
  {
    label: "9 Meses",
    value: 9,
  },
  {
    label: "10 Meses",
    value: 10,
  },
  {
    label: "11 Meses",
    value: 11,
  },
  {
    label: "12 Meses",
    value: 12,
  },
];

export const so_options = [
  {
    label: "Android",
    value: "Android",
  },
  {
    label: "iOS",
    value: "iOS",
  },
  ,
];

export const qrcode_color_options = [
  {
    label: "Rojo",
    value: 0,
  },
  {
    label: "Blanco",
    value: 1,
  },
  ,
  {
    label: "Negro",
    value: 2,
  },
];

export const is_visible_options = [
  {
    label: "Si",
    value: 1,
  },
  {
    label: "No",
    value: 0,
  },
];

export const is_recommended_options = [
  {
    label: "Si",
    value: 1,
  },
  {
    label: "No",
    value: 0,
  },
];

export const is_obliged_options = [
  {
    label: "Si",
    value: 1,
  },
  {
    label: "No",
    value: 0,
  },
];

export const topping_limit_options = [
  {
    label: "Sin limite",
    value: -1,
  },
];

export const accompaniment_limit_options = [
  {
    label: "Sin limite",
    value: -1,
  },
];

export const variant_limit_options = [
  {
    label: "Sin límite",
    value: -1,
  },
];

export const send_mail_options = [
  {
    label: "Si",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];

export const roles_options = [
  {
    label: "Restaurant Admin",
    value: 1,
  },
  {
    label: "Sucursal",
    value: 3,
  },
];

export const plans_options = [
  {
    label: "Básico",
    value: 0,
  },
  {
    label: "Intermedio",
    value: 1,
  },
  {
    label: "Full",
    value: 2,
  },
];

export const days_options = [
  {
    label: "monday",
    value: 0,
  },
  {
    label: "tuesday",
    value: 1,
  },
  {
    label: "wednesday",
    value: 2,
  },
  {
    label: "thursday",
    value: 3,
  },
  {
    label: "friday",
    value: 4,
  },
  {
    label: "saturday",
    value: 5,
  },
  {
    label: "sunday",
    value: 6,
  },
];

export const order_status_options = [
  {
    label: "Pendiente",
    value: "pending",
  },
  {
    label: "En preparación",
    value: "preparing",
  },
  {
    label: "Finalizado",
    value: "confirmed",
  },
  {
    label: "Cancelado",
    value: "canceled",
  },
];

export const set_entity_limit_options = (
  options,
  min,
  max,
  variant = 1,
  symbol = ""
) => {
  const newOptions = [...options];
  for (let i = min; i <= max; i += variant) {
    const label = `${i}${symbol}` === "0%" ? "Sin descuento" : `${i}${symbol}`;
    newOptions.push({ label: label, value: i });
  }

  return newOptions;
};
