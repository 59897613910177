//Dependencies
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import axios from "axios";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useHistory } from "react-router-dom";
import PageLoader from "../components/PageLoader";

//Components
import SchedulesContainer from "../components/SchedulesContainer";
import MenuCategories from "../components/MenuCategories";

//Assets
import "../stylesheets/MenuForm.css";
import "react-tabs/style/react-tabs.css";

//Utils
import * as actions from "../actions";
import { getAll, create, update } from "../utils/services";
import { modalMenuSuccessful, modalMenuError } from "../utils/modals";
import { isBranchUser } from "../utils/managment";

const ENTITY = "menus";

const MenuForm = (props) => {
  const {
    menu,
    action,
    schedules,
    menuCategories,
    menuCategoryDishes,
    name,
    description,
    loggedUser,
    updateMenu,
    clearSchedules,
    addMenuCategory,
    clearMenuCategories,
    addMenuCategoryProduct,
    clearMenuCategoryProduct,
    categories,
    setOnlyAllergens,
    setOnlyProducts,
    setOnlyCategories,
    setOnlyToppings,
    setOnlyAccompaniments,
    setOnlyVariants,
    showLoadingOverlay,
    setIsLoading,
  } = props;

  const { t } = useTranslation();
  const activeRef = useRef();
  const RESTAURANT_ID = loggedUser.restaurant_id;
  const BRANCH_ID = menu?.branch_id || loggedUser.branch_id;
  const MENU_ID = menu?.id;
  const SELECTED_MENU = menu;
  const readOnly =
    isBranchUser(loggedUser.role) && !menu?.branch_id && !!MENU_ID;

  const [scheduleItems, setScheduleItems] = useState(schedules || []);
  const [menuName, setMenuName] = useState(name);
  const [menuDescription, setMenuDescription] = useState(description);
  const { register, handleSubmit } = useForm();
  const history = useHistory();

  const onSubmit = (data, e) => {
    showLoadingOverlay(true);
    const menuTitle = {
      id:
        SELECTED_MENU?.translations.filter(
          ({ locale, att }) =>
            locale == loggedUser.primary_lang && att == "name",
        ).length > 0
          ? SELECTED_MENU?.translations.filter(
              ({ locale, att }) =>
                locale == loggedUser.primary_lang && att == "name",
            )[0].id
          : null,
      att: "name",
      description: menuName,
      locale: loggedUser.primary_lang,
    };
    const menuDescriptionAux = {
      id:
        SELECTED_MENU?.translations.filter(
          ({ locale, att }) =>
            locale == loggedUser.primary_lang && att == "description",
        ).length > 0
          ? SELECTED_MENU?.translations.filter(
              ({ locale, att }) =>
                locale == loggedUser.primary_lang && att == "description",
            )[0].id
          : null,
      att: "description",
      description: menuDescription,
      locale: loggedUser.primary_lang,
    };
    let menu = {
      restaurant_id: RESTAURANT_ID,
      branch_id: BRANCH_ID,
      is_visible: true,
      has_child: false,
      translations_attributes: [],
      schedules_attributes: scheduleItems,
      menu_categories_attributes: menuCategories?.map((cat) => {
        const hash = {
          id: cat.id,
          category_id: cat.category_id,
          slot: cat.slot,
          _destroy: cat._destroy,
        };
        hash.category_attributes = {
          id: cat.category_id,
          translations_attributes: cat.translations_attributes,
        };
        return hash;
      }),
      dishes_attributes: menuCategoryDishes.map((d) => ({
        ...d,
        branch_id: BRANCH_ID,
      })),
      active: activeRef?.current?.checked,
    };

    if (menuTitle.description) menu.translations_attributes.push(menuTitle);
    if (menuDescriptionAux.description)
      menu.translations_attributes.push(menuDescriptionAux);

    const MENU_NAME = menuName;

    if (action === "create") {
      axios
        .post(create[ENTITY](RESTAURANT_ID, BRANCH_ID), { menu })
        .then(() =>
          modalMenuSuccessful("creado", MENU_NAME, t, () => history.goBack()),
        )
        .then(() => e.target.reset())
        .catch(() => modalMenuError("crear", MENU_NAME, t))
        .finally(() => showLoadingOverlay(false));
    } else if (action === "edit") {
      axios
        .put(update[ENTITY](RESTAURANT_ID, MENU_ID, BRANCH_ID), { menu })
        .then(() => {
          data.id = menu.id;
          updateMenu(data);
        })
        .then(() =>
          modalMenuSuccessful("actualizado", MENU_NAME, t, () =>
            history.goBack(),
          ),
        )
        .catch((error) => {
          const { errors } = error.response.data;
          modalMenuError("actualizar", MENU_NAME, t, errors);
        })
        .finally(() => showLoadingOverlay(false));
    }
  };

  useEffect(() => {
    clearSchedules();
    clearMenuCategories();
    clearMenuCategoryProduct();

    axios
      .get(getAll.categories(RESTAURANT_ID, BRANCH_ID))
      .then(({ data }) => setOnlyCategories(data.categories));

    if (action === "edit") {
      setMenuName(menu.name);
      setMenuDescription(menu.description);

      if (menu.menu_categories.length > 0) {
        const MENU_CATEGORIES = menu.menu_categories.map((menu_category) => {
          clearMenuCategoryProduct(menu_category.category_id);
          return {
            id: menu_category.id,
            category_id: menu_category.category_id,
            name: menu_category.category?.name,
            slot: menu_category.slot,
            description: menu_category.category?.description,
          };
        });
        addMenuCategory(
          MENU_CATEGORIES.sort((a, b) => (a.slot > b.slot ? 1 : -1)),
        );
      }
    }

    axios
      .get(getAll.products(RESTAURANT_ID, BRANCH_ID), {
        params: { view: "normal" },
      })
      .then(({ data }) => {
        setOnlyProducts(data.products);
        const dishes = menu?.menu_categories
          ?.map((mc) => mc.category.dishes)
          .flat();
        // ACA HACER ALGO
        if (dishes?.length > 0) {
          dishes
            .sort((a, b) => (a.slot > b.slot ? 1 : -1))
            .map((dish) => {
              let MENU_DISH = data.products.find(
                (product) => product.id == dish.product_id,
              );
              MENU_DISH ??= dish;
              MENU_DISH = {
                ...MENU_DISH,
                product_id: dish.product_id,
                id: dish.id,
                toppings: dish.dish_toppings,
                accompaniments: dish.dish_accompaniments,
                variants: dish.dish_variants,
                allergens: dish.dish_allergens,
                price: dish.price,
                discount_ratio: dish.discount_ratio,
                discount_value: dish.discount_value,
                is_visible: dish.is_visible,
                dynamic_link: dish.dynamic_link,
                can_share: dish.can_share,
              };
              addMenuCategoryProduct({
                restaurantId: RESTAURANT_ID,
                categoryId: dish.category_id,
                selectedProduct: MENU_DISH,
                branchId: BRANCH_ID,
              });
            });
        }
      });

    axios.get(getAll.allergens()).then((res) => {
      setOnlyAllergens(res.data.allergens);
    });
    axios.get(getAll.toppings(RESTAURANT_ID)).then((res) => {
      const { toppings } = res.data;
      const AVAILABLE_TOPPINGS = toppings.filter(
        (top) => top.is_visible != false,
      );
      setOnlyToppings(AVAILABLE_TOPPINGS);
    });
    axios.get(getAll.accompaniments(RESTAURANT_ID)).then((res) => {
      const { accompaniments } = res.data;
      const AVAILABLE_ACCOMPANIMENTS = accompaniments.filter(
        (acc) => acc.is_visible != false,
      );
      setOnlyAccompaniments(AVAILABLE_ACCOMPANIMENTS);
    });
    axios.get(getAll.variants(RESTAURANT_ID)).then((res) => {
      const { variants } = res.data;
      const AVAILABLE_VARIANTS = variants.filter(
        (variant) => variant.is_visible != false,
      );
      setOnlyVariants(AVAILABLE_VARIANTS);
    });
    setIsLoading(false);
  }, [menu]);

  return (
    <section>
      <form className="cartear-menu-form" onSubmit={handleSubmit(onSubmit)}>
        <Tabs className="w-100">
          <TabList>
            <Tab>{t("forms.menu.name")}</Tab>
            <Tab>{t("forms.menu.dishesAndCategories")}</Tab>
          </TabList>

          <TabPanel>
            <section className="tab-content-container">
              <section className="menu-form-left">
                <section className="row">
                  <section className="form-group">
                    <label>{t("forms.menu.menuName")}</label>
                    <input
                      readOnly={readOnly}
                      name="name"
                      className="form-control"
                      defaultValue={menuName}
                      value={menuName}
                      maxLength={50}
                      ref={register({
                        required: true,
                        message: t("forms.isRequired"),
                      })}
                      onChange={(e) => setMenuName(e.target.value)}
                    />
                  </section>
                </section>
                <section className="row">
                  <section className="menu-description">
                    <label>{t("forms.menu.description")}</label>
                    <textarea
                      readOnly={readOnly}
                      name="description"
                      maxLength={200}
                      className="form-control"
                      defaultValue={menuDescription}
                      value={menuDescription}
                      onChange={(e) => setMenuDescription(e.target.value)}
                      ref={register}
                    />
                  </section>
                </section>
                <section className="row">
                  <label>{t("forms.menu.active")}</label>
                  <input
                    type="checkbox"
                    defaultChecked={menu?.active}
                    ref={activeRef}
                  />
                </section>
              </section>
              <section className="menu-form-right">
                <SchedulesContainer
                  scheduleItems={scheduleItems}
                  setScheduleItems={setScheduleItems}
                  readOnly={readOnly}
                />
              </section>
            </section>
          </TabPanel>
          <TabPanel>
            <MenuCategories
              categories={categories}
              readOnly={readOnly}
              loggedUser={loggedUser}
            />
          </TabPanel>
        </Tabs>
        <section className="row">
          {!readOnly && (
            <section className="form-submit-btn">
              <input
                type="submit"
                className="btn gradient-button"
                value={t("commons.buttons.save")}
                style={{ position: "fixed", bottom: "100px" }}
              />
            </section>
          )}
        </section>
      </form>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedUser: state.loggedUser,
    schedules: state.schedules,
    menuCategories: state.menuCategories,
    menuCategoryDishes: state.menuCategoryDishes,
    categories: state.categories,
  };
};

export default connect(mapStateToProps, actions)(MenuForm);
