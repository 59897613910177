//Dependencies
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

//Components
import DatatableFilter from "./DatatableFilter";
import ModelsFilterButton from "./ModelsFilterButton";

//Assets
import "../stylesheets/datatableHeader.css";
import AddIcon from "@material-ui/icons/Add";

//Utils
import { getAll, clone } from "../utils/services";
import { entities } from "../utils/partials.json";
import {
  modalMenuClonedSuccessful,
  modalMenuClonedError,
} from "../utils/modals";
import * as actions from "../actions";

const DatatableHeader = (props) => {
  const { t } = useTranslation()
  const { entity, filteredItems, loggedUser, menus, setMenus } = props;
  const [branches, setBranches] = useState([]);

  useEffect(() => {
    if (entity === "products" || entity === "menus") {
      const BRANCHES_URL = getAll.branches(loggedUser.restaurant_id);

      axios.get(BRANCHES_URL).then((res) => {
        const { branches } = res.data;
        setBranches(branches);
      });
    }
  }, []);

  const handleOptionalButtonClick = async () => {
    const filteredMenus = await filteredItems.map((menu) => ({
      id: menu.id,
      name: menu.name || menu.title,
    }));

    let options = {};
    filteredMenus.map((item) => {
      options[item.id] = item.name;
    });

    const MENU_SELECTED = await Swal.fire({
      title: `Clonar ${entities[entity].singular}`,
      html: `Clonar le permite crear su ${entities[entity].singular} a partir de un template generado por el adminstrador.<br><br>
            Seleccioná el ${entities[entity].singular} que querés clonar.<br><br>
            <h4>${entities[entity].singular}</h4>`,
      input: "select",
      inputOptions: options,
      inputPlaceholder: `Selecciona un ${entities[entity].singular}`,
      confirmButtonText: "Seleccionar",
      showCancelButton: true,
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (!!value) {
            resolve();
          } else {
            resolve(
              `Debes seleccionar un ${entities[entity].singular} para continuar`
            );
          }
        });
      },
    });

    if (!MENU_SELECTED.isConfirmed) return;

    const SELECTED_BRANCHES = await Swal.fire({
      title: "Seleccione las Sucursales",
      html: branches
        .map((branch) => {
          return `<div class="branches-cb__group">
          <input
          type="checkbox"
          id=${branch.id}
          value=${branch.name}
          class="branch-cb"
          />
          <label for=${branch.id}>${branch.name}</label>
          </div>`;
        })
        .join(""),
      confirmButtonText: "Confirmar",
      preConfirm: () => {
        const branches_cb = Swal.getPopup().querySelectorAll(".branch-cb");
        const SELECTED_BRANCHES = Array.from(branches_cb)
          .filter((cb) => cb.checked)
          .map((branch) => ({ id: branch.id, name: branch.name }));

        if (SELECTED_BRANCHES.length === 0) {
          Swal.showValidationMessage(
            "Debes seleccionar al menos una sucursal para continuar"
          );
        }

        return SELECTED_BRANCHES;
      },
    });

    if (MENU_SELECTED.value && SELECTED_BRANCHES.value) {
      axios
        .post(clone[entity](loggedUser.restaurant_id, MENU_SELECTED.value), {
          id: MENU_SELECTED.value,
          type: entity,
          branches_id: SELECTED_BRANCHES.value.map((branch) => branch.id),
        })
        .then(({ data }) => {
          setMenus([...menus, ...data]);
          modalMenuClonedSuccessful(t);
        })
        .catch(() => modalMenuClonedError(t));
    }
  };

  const loginAsEntity = window.localStorage.getItem("restaurant");
  const parentName = loginAsEntity
    ? JSON.parse(loginAsEntity).name
    : loggedUser.restaurant_name;
  const RESTAURANT_NAME = () => {
    if (entity === "branches") {
      return parentName;
    }
    return null;
  };

  return (
    <section className="datatable-header">
      <section className="datatable-header-title">
        <h3>
          {RESTAURANT_NAME()} Lista de {entities[entity].title}
        </h3>
      </section>
      <section className="datatable-header-actions">
        <section className="search-section">
          <DatatableFilter entity={entity} />
        </section>
        {!!entities[entity].filterButton && (
          <ModelsFilterButton buttonName={entities[entity].filterButton.name} />
        )}
        {["super_admin", "restaurant_admin"].includes(loggedUser.role) &&
          !!entities[entity].optionalButton && (
            <section
              className="create-section"
              onClick={() => handleOptionalButtonClick()}
            >
              <div className="create-button">
                {entities[entity].optionalButton.name}
              </div>
            </section>
          )}
        {!!entities[entity].singular && (
          <section className="create-section">
            <Link to={`./${entity}/create`} className="create-button">
              <AddIcon />
              CREAR {entities[entity].singular.toUpperCase()}
            </Link>
          </section>
        )}
      </section>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    currentSection: state.currentSection,
    loggedUser: state.loggedUser,
    filteredItems: state.filteredItems,
    menus: state.menus,
  };
};

export default connect(mapStateToProps, actions)(DatatableHeader);
