//Dependencies
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import "@google/model-viewer";
import firebase from "firebase";
import "@firebase/firestore";
import "@firebase/auth";

//Assets
import "../stylesheets/ModelForm.css";
import GIF_Loader from "../images/red-loader.gif";

//Utils
import { getAll, update } from "../utils/services";
import { modalModelSuccessful, modalModelError } from "../utils/modals";
import * as actions from "../actions";

const ModelForm = (props) => {
  const { action, selectedEntity, updateProduct, showLoadingOverlay } = props;

  const { id, restaurant_id, title, url_3d_web, translations } = selectedEntity;

  const { t } = useTranslation();
  const [modelSource, setModelSource] = useState(null);
  const [poster, setPoster] = useState(GIF_Loader);
  const [URL3DWeb, setURL3DWeb] = useState(url_3d_web);
  const [languages, setLanguages] = useState([]);

  const { register, handleSubmit } = useForm();
  const history = useHistory();

  useEffect(() => {
    handleSetPreview3DModel();

    axios.get(getAll.languages).then(({ data }) => {
      const ORGANIZED_LANGUAGES = data.languages.sort((a) =>
        a.code == "es" ? -1 : 1,
      );

      setLanguages(ORGANIZED_LANGUAGES);
    });
  }, []);

  const handleSetPreview3DModel = () => {
    if (URL3DWeb && URL3DWeb.includes("gs://cartear-f8ceb.appspot.com/")) {
      const MODEL_PATH = URL3DWeb?.split("gs://cartear-f8ceb.appspot.com/")[1];

      const CONFIG = {
        apiKey: "AIzaSyDnPB9bWEvQg3R-89rBLXEPz4B7M0uH69U",
        authDomain: "cartear-f8ceb.firebaseapp.com",
        databaseURL: "https://cartear-f8ceb.firebaseio.com",
        storageBucket: "cartear-f8ceb.appspot.com",
      };

      if (firebase.apps.length === 0) firebase.initializeApp(CONFIG);

      const STORAGE = firebase.storage();
      const STORAGE_REF = STORAGE.ref();
      const TANG_REF = STORAGE_REF.child(MODEL_PATH);

      firebase
        .auth()
        .signInAnonymously()
        .then(function () {
          TANG_REF.getDownloadURL()
            .then(function (url) {
              setModelSource(url);
            })
            .catch(function (error) {
              console.error(error);
              setPoster(GIF_Loader);
            });
        });
    } else if (URL3DWeb) {
      const splittedUrl = URL3DWeb.split('.');
      if(splittedUrl[splittedUrl.length - 1] == 'spin'){
        setModelSource(URL3DWeb);
      }
    }
  };

  const onSubmit = (data) => {
    showLoadingOverlay(true);
    let product = {
      ...selectedEntity,
      url_3d_web: URL3DWeb !== "" ? URL3DWeb : null,
    };

    if (action === "edit") {
      axios
        .put(update.products(restaurant_id, id), { product })
        .then(() => {
          data.id = id;
          updateProduct(data);
        })
        .then(() =>
          modalModelSuccessful("actualizado", t, () => history.goBack()),
        )
        .catch(() => modalModelError("actualizar", t))
        .finally(() => showLoadingOverlay(false));
    }
  };

  const loadSirv = (components) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://scripts.sirv.com/sirvjs/v3/sirv.js";
      script.type = "text/javascript";
      script.async = true;
      if (components) {
        script.dataset.components = components;
      }
      script.onload = resolve;
      document.body.appendChild(script);
    });
  };

  useEffect(() => {
    showLoadingOverlay(true);
    loadSirv("spin, zoom, video").then(() => {
      window.Sirv.stop();
      window.Sirv.start();
      showLoadingOverlay(false);
    });
  }, [modelSource]);

  return (
    <section>
      <h2>{title}</h2>
      <form className="cartear-form" onSubmit={handleSubmit(onSubmit)}>
        <section className="model-form-container">
          <section className="row">
            <section className="model-viewer__container">
              <div
                className="Sirv"
                data-src={modelSource}
                id="sirv-viewer"
                data-options="zoom:false"
                fullScreen={false}
              />
            </section>
            <section className="model-main-info">
              <section className="model-name">
                <label>Modelo Web</label>
                <input
                  name="url_3d_web"
                  className="form-control"
                  value={URL3DWeb || ""}
                  ref={register}
                  onChange={(e) => setURL3DWeb(e.target.value)}
                  onBlur={() => {
                    handleSetPreview3DModel();
                  }}
                />
              </section>
            </section>
          </section>
        </section>

        <section className="row">
          <section className="form-submit-btn">
            <input
              type="submit"
              className="btn gradient-button"
              value="GUARDAR"
            />
          </section>
        </section>
      </form>
    </section>
  );
};

export default connect(null, actions)(ModelForm);
