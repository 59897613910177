const forms = {
  forms: {
    inputRequired: "Este campo es requerido",
    withoutResult: "Sin resultados",
    branch: {
      name: "Nombre",
      branch: "Sucursal",
      urlName: "Nombre de URL",
      isVisible: "¿Visible?",
      description: "Descripción",
      descriptionPlaceholder: "Descripción de la sucursal",
      subtitle: "Subtitulo",
      subtitlePlaceholder: "Branch subtitulo",
      address: "Dirección",
      aditionalInfo: "Información Adicional",
      price: "Precio cubierto",
      website: "Sitio Web",
      whatsapp: "Whatsapp",
      facebook: "Facebook",
      instagram: "Instagram",
      phone: "Teléfono",
      phonePlaceholder: "Coloque número de teléfono",
    },
    menu: {
      name: "Menú",
      dishesAndCategories: "Platos y Categorías",
      menuName: "Nombre del menú",
      description: "Descripción",
      active: "Activo",
    },
    menuCategories: {
      addCategory: "AGREGAR CATEGORÍA",
    },
    accompaniment: {
      title: "Nombre del acompañamiento",
      limit: "Límite",
      isVisible: "¿Es visible?",
      isRequired: "¿Es obligatorio?",
    },
    allergen: {
      title: "Nombre del Alérgeno",
      description: "Descripción",
    },
    topping: {
      title: "Nombre del Topping",
      limit: "Límite",
      isVisible: "¿Es visible?",
      isRequired: "¿Es obligatorio?",
    },
    variant: {
      title: "Nombre de la Variante",
      isVisible: "¿Es visible?",
      isRequired: "¿Es obligatorio?",
    },
    category: {
      title: "Nombre de la categoria",
      description: "Descripción de la categoria",
    },
    categoryProduct: {
      description: "Descripción",
      addProduct: "Agregar producto",
      removeProduct: "Quitar producto",
      removeCategory: "Quitar categoria",
    },
    product: {
      title: "Nombre del Producto",
      isRecommended: "¿Producto Destacado?",
      isVisible: "¿Visible?",
      price: "Precio",
      finalPrice: "Precio final",
      discount: "Descuento",
      description: "Descripción",
      summary: "Resumen",
      visible: "Visible",
      hide: "Oculto",
    },
    items: {
      toppingTitle: "Lista de Items del {{entity}}",
      accompanimentTitle: "Lista de Items del {{entity}}",
      variantTitle: "Lista de Items de la {{entity}}",
      addItem: "AGREGAR ITEM",
      price: "Precio",
      isVisible: "¿Visible?",
    },
  },
};

export default forms;
