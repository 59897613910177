//Dependencies
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

//Components
import TranslationsContainer from "../components/TranslationsContainer";

//Assets
import * as actions from "../actions";

//Utils
import { get } from "../utils/services";

const Translations = (props) => {
  const {
    loggedUser: { role, restaurant_id },
    entity,
    selectedEntity,
  } = props;

  const [selectedEntityTranslation, setSelectedEntityTranslation] = useState();
  const { id } = useParams();
  const ENTITY_ID = id ? id : selectedEntity.id;
  //const RESTAURANT_ID = role !== "super_admin" ? restaurant_id : null;
  const RESTAURANT_ID = restaurant_id || selectedEntity.restaurant_id;

  useEffect(() => {
    axios.get(get[entity](ENTITY_ID, RESTAURANT_ID)).then((res) => {
      console.log(res.data)
      setSelectedEntityTranslation(res.data);
    });
  }, []);

  if (!selectedEntityTranslation) return null;

  return (
    <section className="container">
      <div className="form-title">
        <h3>Traducciones</h3>
      </div>
      <TranslationsContainer entity={entity} selectedEntity={selectedEntityTranslation} />
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedUser: state.loggedUser,
    selectedEntity: state.selectedEntity,
    entity: state.entity,
  };
};

export default connect(mapStateToProps, actions)(Translations);
